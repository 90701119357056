import React, { useContext, useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { useMutation } from '@apollo/client';
import {
  useCheckoutEffect,
  CART_LINES_UPDATE,
  CART_LINES_REMOVE,
} from 'helpers/graphql/checkout';
import { StorefrontContext } from 'providers/storefront';
import { checkCustomProductInventory } from 'helpers/check-custom-products-inventory';
import { initiateCheckoutEvent } from 'lib/events/fb-pixel/initiate-checkout';
import PcModelDelayAlert from 'containers/PcModel/PcModelDelayAlert';
import cn from 'classnames';
import LineItemsGroup from '../LineItem/LineItemsGroup';
import CartLoader from '../CartLoader';
import breakPoints from 'helpers/Styles';
import { fixDecimalPlaces } from 'helpers/smallFixes';

const CartContent = () => {
  const { checkout, setCheckout } = useContext(StorefrontContext);
  const [inventoryChecked, setInventoryChecked] = useState(false);
  const [delayedItems, setDelayedItems] = useState([]);
  const [loading, setLoading] = useState(false);

  const [
    lineItemUpdateMutation,
    {
      data: lineItemUpdateData,
      loading: lineItemUpdateLoading,
      error: lineItemUpdateError,
    },
  ] = useMutation(CART_LINES_UPDATE);

  const [
    lineItemRemoveMutation,
    {
      data: lineItemRemoveData,
      loading: lineItemRemoveLoading,
      error: lineItemRemoveError,
    },
  ] = useMutation(CART_LINES_REMOVE);

  useCheckoutEffect(lineItemUpdateData, 'CART_LINES_UPDATE', setCheckout);
  useCheckoutEffect(lineItemRemoveData, 'CART_LINES_REMOVE', setCheckout);

  const updateLineItem = async (lineItemId, quantity, type) => {
    if (type == 'rts') {
      let productId = '';
      await checkout.lines.edges.filter((itm) => {
        if (itm.node.id == lineItemId) {
          const variantId = itm.node.merchandise.id;
          const value = JSON.parse(itm.node.attributes[0].value);

          if (variantId == value.product_id) {
            productId = value.product_id;
          }
        }
      });
      if (productId != '') {
        let LineItemIds = [];
        checkout.lines.edges.map((itm) => {
          const lineId = itm.node.id;
          let value = '';
          if (itm.node.attributes[0].key != 'link') {
            value = JSON.parse(itm.node.attributes[0].value);
          }
          const variantId = itm.node.merchandise.id;
          const qty = parseInt(itm.node.quantity) - 1;

          if (value.product_id == productId) {
            LineItemIds.push({ id: lineId, quantity: parseInt(quantity, 10) });
          }
        });
        if (Cookies.get('checkoutIdNew')) {
          const variables = {
            checkoutId: Cookies.get('checkoutIdNew'),
            lines: LineItemIds,
          };
          lineItemUpdateMutation({ variables });
        } else {
          const variables = {
            checkoutId: checkout.id,
            lines: LineItemIds,
          };
          lineItemUpdateMutation({ variables });
        }
      } else {
        if (Cookies.get('checkoutIdNew')) {
          const variables = {
            checkoutId: Cookies.get('checkoutIdNew'),
            lines: [{ id: lineItemId, quantity: parseInt(quantity, 10) }],
          };
          lineItemUpdateMutation({ variables });
        } else {
          const variables = {
            checkoutId: checkout.id,
            lines: [{ id: lineItemId, quantity: parseInt(quantity, 10) }],
          };
          lineItemUpdateMutation({ variables });
        }
      }
    } else {
      if (Cookies.get('checkoutIdNew')) {
        const variables = {
          checkoutId: Cookies.get('checkoutIdNew'),
          lines: [{ id: lineItemId, quantity: parseInt(quantity, 10) }],
        };

        lineItemUpdateMutation({ variables });
      } else {
        const variables = {
          checkoutId: checkout.id,
          lines: [{ id: lineItemId, quantity: parseInt(quantity, 10) }],
        };
        lineItemUpdateMutation({ variables });
      }
    }
  };

  const removeAllLineItems = () => {
    let LineItemIds = [];
    checkout.lines.edges.map((itm) => {
      LineItemIds.push(itm.node.id);
    });

    if (Cookies.get('checkoutIdNew')) {
      const variables = {
        checkoutId: Cookies.get('checkoutIdNew'),
        lineItemIds: LineItemIds,
      };
      lineItemRemoveMutation({ variables });
    } else {
      const variables = {
        checkoutId: checkout.id,
        lineItemIds: LineItemIds,
      };
      lineItemRemoveMutation({ variables });
    }
  };

  const removeLineItem = async (lineItemId, type) => {
    if (type == 'rts') {
      let productId = '';
      await checkout.lines.edges.filter((itm) => {
        if (itm.node.id == lineItemId) {
          const variantId = itm.node.merchandise.id;
          let value = '';
          if (itm.node.attributes[0].key != 'link') {
            value = JSON.parse(itm.node.attributes[0].value);
          }

          if (variantId == value.product_id) {
            productId = value.product_id;
          }
        }
      });
      if (productId != '') {
        let LineItemIds = [];
        checkout.lines.edges.map((itm) => {
          const lineId = itm.node.id;
          let value = '';
          if (itm.node.attributes[0].key != 'link') {
            value = JSON.parse(itm.node.attributes[0].value);
          }
          const variantId = itm.node.merchandise.id;

          if (value.product_id == productId) {
            LineItemIds.push(lineId);
          }
        });

        if (Cookies.get('checkoutIdNew')) {
          const variables = {
            checkoutId: Cookies.get('checkoutIdNew'),
            lineItemIds: LineItemIds,
          };
          lineItemRemoveMutation({ variables });
        } else {
          const variables = {
            checkoutId: checkout.id,
            lineItemIds: LineItemIds,
          };
          lineItemRemoveMutation({ variables });
        }
      } else {
        if (Cookies.get('checkoutIdNew')) {
          const variables = {
            checkoutId: Cookies.get('checkoutIdNew'),
            lineItemIds: [lineItemId],
          };
          lineItemRemoveMutation({ variables });
        } else {
          const variables = {
            checkoutId: checkout.id,
            lineItemIds: [lineItemId],
          };
          lineItemRemoveMutation({ variables });
        }
      }
    } else {
      if (Cookies.get('checkoutIdNew')) {
        const variables = {
          checkoutId: Cookies.get('checkoutIdNew'),
          lineItemIds: [lineItemId],
        };
        lineItemRemoveMutation({ variables });
      } else {
        const variables = {
          checkoutId: checkout.id,
          lineItemIds: [lineItemId],
        };
        lineItemRemoveMutation({ variables });
      }
    }
  };

  const readyToShipProducts = checkout?.lines?.edges.filter(
    (item) =>
      item?.node?.merchandise?.product?.productType
        .replace(/ /g, '')
        .toLowerCase() === 'readytoship' &&
      !item?.node?.merchandise?.product?.tags?.includes('custom'),
  );

  const accessoriesProducts = checkout?.lines?.edges.filter(
    (item) =>
      item?.node?.merchandise?.product?.productType === 'Accessories' ||
      item?.node?.merchandise?.product?.productType === 'Monitor',
  );

  const customProducts = checkout?.lines?.edges.filter((item) =>
    item?.node?.merchandise?.product?.tags.includes('custom'),
  );

  const otherProducts = checkout?.lines?.edges.filter(
    (item) => item?.node?.merchandise?.product?.productType === '',
  );

  const minArray = checkout?.lines?.edges.map((item) => {
    if (item?.node?.merchandise?.compareAtPrice) {
      return (
        item.node.quantity *
        (item.node.merchandise.compareAtPrice - item.node.merchandise.price)
      );
    }
    return 0;
  });

  const totalSaved = minArray.reduce((x, y) => x + y, 0);

  useEffect(() => {
    console.log('cart checkout is', checkout);

    if (checkout.lines) {
      let feeItem = checkout.lines.edges.find(
        (edge) => edge.node.title == 'Payment Method Fee',
      );
      if (feeItem) {
        removeLineItem(feeItem.node.id, 'fee');
      }
      // console.log('feeItem', feeItem);
    }
  });

  const openCheckout = async () => {
    setLoading(true);

    if (!customProducts.length || inventoryChecked) {
      setLoading(false);
      window.location.href = checkout.checkoutUrl;
      initiateCheckoutEvent(checkout);
    } else {
      const inventory = await Promise.all(
        customProducts.map((customProduct) =>
          checkCustomProductInventory(customProduct),
        ),
      );

      let delayedItem = false;
      inventory?.forEach((item, index) => {
        item?.forEach((i) => {
          if (i?.policy === 'DENY' && i?.available < 1) {
            delayedItem = true;
            setDelayedItems((prev) => [...prev, customProducts[index].node.id]);
          }
        });
      });

      if (delayedItem) {
        setLoading(false);
        setInventoryChecked(true);
      } else {
        setLoading(false);
        window.location.href = checkout.checkoutUrl;
        initiateCheckoutEvent(checkout);
      }
    }
  };

  if (lineItemUpdateLoading || lineItemRemoveLoading) {
    return <CartLoader />;
  }
  return (
    <div
      style={{
        height: '90%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        paddingBottom: '15px',
      }}
    >
      <span className="clear-all" onClick={removeAllLineItems}>
        Clear All
      </span>
      <ul className="Cart__line-items" id="scroll">
        <LineItemsGroup
          title="READY TO SHIP PCs"
          lineItems={readyToShipProducts}
          removeLineItemInCart={removeLineItem}
          updateLineItemInCart={updateLineItem}
          key={1}
        />

        <LineItemsGroup
          lineItems={customProducts}
          delayedItems={delayedItems}
          title="CUSTOM BUILDS"
          removeLineItemInCart={removeLineItem}
          updateLineItemInCart={updateLineItem}
          key={2}
        />

        <LineItemsGroup
          lineItems={accessoriesProducts}
          removeLineItemInCart={removeLineItem}
          updateLineItemInCart={updateLineItem}
          title="ACCESSORIES"
          key={3}
        />

        <LineItemsGroup
          lineItems={otherProducts}
          removeLineItemInCart={removeLineItem}
          updateLineItemInCart={updateLineItem}
          title="OTHERS"
          key={4}
        />
      </ul>
      <footer className="Cart__footer">
        <div className="Cart-info clearfix">
          <div className="Cart-info__total Cart-info__small">Subtotal</div>
          <div className="Cart-info__pricing">
            <span className="pricing">
              {`$ `}
              {fixDecimalPlaces(checkout?.cost.subtotalAmount.amount)}
            </span>
          </div>
        </div>

        <div className="Cart-total">
          <div className="Cart-info__total">Total</div>
          <div className="Cart-total-price">
            <span className="pricing">
              {`$ `}
              {fixDecimalPlaces(checkout?.cost.totalAmount.amount)}
            </span>
          </div>
        </div>

        {totalSaved && totalSaved.toFixed(2) !== 0 ? (
          <div className="Cart-total-saved">
            <div className="Cart-info__saved">YOU SAVED</div>
            <div className="Cart-total-saved">
              <span>
                $
                {totalSaved
                  .toFixed(2)
                  .toLocaleString()
                  .replace(/(\d{1,2})(?=(\d{3})+\.)/g, '$1,')}
              </span>
            </div>
          </div>
        ) : null}

        {inventoryChecked && (
          <div className="delay-alert-container">
            <PcModelDelayAlert checkout />
          </div>
        )}

        <button
          type="button"
          className={cn('Cart__checkout button', { checkoutDisabled: loading })}
          onClick={openCheckout}
          disabled={loading}
          id="checkout-btn"
        >
          {loading ? 'LOADING..' : 'CHECKOUT'}
        </button>
      </footer>

      <style jsx>
        {`
          .Cart__line-items {
            flex: 1 0 auto;
            margin: 0;
            padding: 0;
            height: 60%;
            overflow: auto;
            padding-right: 40px;
          }
          .Cart__footer {
            flex: 0 0 auto;
            padding-right: 40px;
          }
          .Cart__checkout {
            margin-top: ${inventoryChecked ? '10px' : '20px'};
            display: block;
            width: 100% !important;
            border: 1px solid black;
            border-radius: 3px !important;
            background: #950810;
            color: #e4e4e4;
            font-family: 'Roboto-Medium';
            font-size: 16px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 24px;
          }
          .checkoutDisabled {
            background: #949494;
          }
          .Cart__checkout:hover {
            background: #800006;
          }
          .Cart-info {
            display: flex;
            justify-content: space-between;
            margin-bottom: ${inventoryChecked ? '10px' : '30px'};
            margin-top: ${inventoryChecked ? '10px' : '30px'};
            padding-bottom: ${inventoryChecked ? '10px' : '30px'};
            border-bottom: 1px solid #343434;
          }
          .Cart-total {
            display: flex;
            justify-content: space-between;
          }
          .Cart-info__total {
            text-transform: uppercase;
            color: #949494;
            font-family: 'Roboto-Medium';
            font-size: 16px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 24px;
          }
          .Cart-total-price {
            color: #e4e4e4;
            font-family: 'big_noodle_titling';
            font-size: 31px;
            letter-spacing: 0;
            line-height: 40px;
            text-align: right;
          }
          .Cart-info__pricing {
            color: #e4e4e4;
            font-family: 'Roboto-Medium';
            font-size: 16px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 24px;
            text-align: right;
          }
          .Cart-total-saved {
            display: flex;
            justify-content: space-between;
            margin-top: ${inventoryChecked ? '5px' : '10px'};
          }
          .Cart-total-saved span,
          .Cart-info__saved {
            color: #fd0300;
            font-family: 'Roboto-Medium';
            font-size: 16px;
            letter-spacing: 0;
            line-height: 24px;
            text-align: right;
          }
          .delay-alert-container {
            margin-top: 10px;
          }
          #scroll::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            background-color: #f5f5f5;
          }
          #scroll::-webkit-scrollbar {
            width: 6px;
            background-color: #f5f5f5;
          }
          #scroll::-webkit-scrollbar-thumb {
            background-color: #950810;
          }
          @media screen and (max-width: 550px) {
            .Cart__footer {
              padding-right: 20px;
            }
          }
          @media (max-width: ${breakPoints.small}) {
            .delay-alert-container {
              margin-top: 2px;
            }
            .Cart-total-price {
              line-height: ${inventoryChecked ? '30px' : '40px'};
              font-size: 28px;
            }
            .Cart-total-saved {
              margin-top: ${inventoryChecked ? '5px' : '10px'};
            }
          }
          .clear-all {
            font-size: 15px;
            color: #df000d;
            margin-right: 11%;
            cursor: pointer;
            position: absolute;
            top: 42px;
            right: 0;
            z-index: 99;
          }
        `}
      </style>
    </div>
  );
};

export default CartContent;
