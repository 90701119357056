export const checkCustomProductInventory = async (customProduct) => {
  const variants = [];
  customProduct.node.attributes.forEach((att) => {
    if (att?.key?.includes('_lineitem')) {
      const v = JSON.parse(att.value);
      variants.push(`gid://shopify/ProductVariant/${v.variantId}`);
    }
  });
  const inventory = await fetch('/api/shopify/inventory/check-inventory', {
    method: 'POST',
    body: JSON.stringify({ variants }),
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((response) => response.json())
    .catch((error) => {
      console.log(error);
      console.log(variants);
      console.log(
        'Please check line 9 in file helpers/check-custom-products-inventory.js',
      );
      console.log("Check or verify if the variant id's are all valid.");
    });
  return inventory;
};
